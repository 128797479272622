import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout-minhelt/layout";
import Seo from "../components/Seo";
import EventForm from "../components/ContactForm/event-form";
//import { string } from "../util/strings";
import { useSelector, useDispatch } from "react-redux";
import Hero from "../components/hero";
//import FramePlaceholder from "../components/frame-placeholder"
import Hs from "../images/h-sperator.svg";

const IndexPage = ({ data, ...props }) => {
  const { node } = data;

  console.log(node);
  const location = props.location;
  const { search } = location;

  const success = search === "?success";

  const seo = {};

  if ( !node.status) {
    return (
      <Layout>
        <Seo {...seo} />
        <Hero
        className="mt-[90px] lg:mt-[126px]  h-[147px] lg:h-[350px] "
        title={"הרשמה לאירוע"}
        subtitle={""}
        url={"/backgrounds/contact-us.svg"}
      />
<section className="lg:py-24 ">
        <div className="max-w-[800px] mx-auto text-center">
          <p className="text-xl">הטופס לא זמין כרגע</p>
        </div>
        </section>
      </Layout>
    );
  }

  return (
    <Layout>
      <Seo {...seo} />
      <Hero
        className="mt-[90px] lg:mt-[126px]  h-[147px] lg:h-[350px] "
        title={"הרשמה לאירוע"}
        subtitle={""}
        url={"/backgrounds/contact-us.svg"}
      />

      <section className="lg:py-12 ">
        {success && <Success />}

        {!success && (
          <div>
            <div className="space-y-8 py-12 px-4 lg:px-24  ">
              <h2 className="mb-2 text-blue text-[34px]  lg:text-center">
                <img src={Hs} alt="" className="inline ml-1.5" />
                {node.title}
              </h2>
              <div className="max-w-[800px] mx-auto" dangerouslySetInnerHTML={{__html: node.body.processed}} />
               
              <ContactBlock />
            </div>
          </div>
        )}
      </section>
    </Layout>
  );
};

export default IndexPage;

const ContactBlock = () => {
  const id = "event-registration";

  const { forms } = useSelector((state) => state.contact);
  const { message, loading } = forms.find((e) => e.id === id);
  const dispatch = useDispatch();

  const sendContact = (values) => {
    console.log( values );

    const data = {
      //"field_name": [values.name],
      field_phone: [values.phone],
      field_email: [values.email],
      field_number_participants: [values.participants],

      field_last_name: [values.last_name],
      field_first_name: [values.first_name],
      field_posision: [values.position],
      field_company: [values.company],
      contact_form: "_kns_hthdswt",
      subject: ["event"],
      //"subject": [values.subject],
      //"field_subject": [values.subject],
      //"message": [values.text]
      message: ["event"],
    };

    //console.log( data );

    dispatch({ type: "contact/send", payload: { id: id, data: data } });

    //console.log('data', data)
  };

  return (
    <div className="w-full ">
      <EventForm onSubmit={sendContact} loading={loading} message={message} />
    </div>
  );
};

export const Head = () => <title>הרשמה</title>;

const Success = () => {
  return (
    <div className="container my-8 text-center">
      <h2 className="text-2xl">תודה שכתבת לנו</h2>
      <div>אחד מהנציגים שלנו יחזור אלייך בהקדם</div>
    </div>
  );
};

export const query = graphql`
  query EeventRegistrationQuery {
    node: nodePage(id: { eq: "996a4485-60a6-5464-97ad-a91b73e89cda" }) {
      status
      title
      body {
        processed
      }
    }
  }
`;
