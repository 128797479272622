import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { string } from "../../util/strings";

const validate = (values) => {
  const errors = {};
  

  const required = string("form.require");

  //console.log(values)

//   if (!values.subject) {
//     errors.subject = required;
//   }

  if (!values.first_name) {
    errors.first_name = required;
  }

  if (!values.last_name) {
    errors.last_name = required;
  }

//   if (!values.text) {
//     errors.text = required;
//   }

  if (!values.phone) {
    errors.phone = required;
  }

  if (!values.email) {
    errors.email = required;
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = string("form.Invalid email address");
  }

  if (!values.participants) {
    errors.participants = required;
  }

  if (!values.position || values.position === string("contact.position")) {
    errors.position = required;
  }

  return errors;
};

const EventForm = (props) => {
  const { onSubmit, loading /* , message */ } = props;

  const positions = [string("contact.position"), `בעל נכס באזור התעשייה`, `יזם`, `בעל מקצוע`,'שמאי', 'מתווך', 'עורך דין', 'כלכלן', 'מנהל פרויקטים', `אחר`];
  

  return (
    <div className="container ">
      <Formik
        initialValues={{
          subject: "",
          message: "",
          first_name: "",
          last_name: "",
          phone: "",
          email: "",
          position: "",
          participants: "",
          company: "",
        }}
        validate={validate}
        onSubmit={async (values) => {
          onSubmit(values);
        }}
      >
        <Form className="space-y-8">
          <div className=" grid grid-cols-2   gap-x-3 gap-y-3 max-w-4xl mx-auto">
            


            {/* <div className="w-full">
              <Field
                as="select"
                name="participants"
                className="form-control px-4 py-3 border placeholder:text-grey2 border-grey2 w-full"
              >
                {participants.map((e, index) => (
                  <option value={e} key={index}>
                    {e}
                  </option>
                ))}
              </Field>

              <ErrorMessage name="participants" component="div" className="error" />
            </div> */}
            <div className="w-full">
              <Field
                id="first_name"
                name="first_name"
                placeholder={string("contact.first_name")}
                className="form-control px-4 py-3 border placeholder:text-grey2 border-grey2 w-full"
              />
              <ErrorMessage
                name="first_name"
                component="div"
                className="error"
              />
            </div>
            <div className="w-full">
              <Field
                id="last_name"
                name="last_name"
                placeholder={string("contact.last_name")}
                className="form-control px-4 py-3 border placeholder:text-grey2 border-grey2 w-full"
              />
              <ErrorMessage
                name="last_name"
                component="div"
                className="error"
              />
            </div>

            <div className="w-full">
              <Field
                id="phone"
                name="phone"
                step="1"
                min="1"
                max="5"
                className="form-control  px-4 py-3 border placeholder:text-grey2  border-grey2 w-full"
                placeholder={string("contact.phone")}
              />
              <ErrorMessage name="phone" component="div" className="error" />
            </div>

            <div className="w-full">
              <Field
                id="email"
                name="email"
                placeholder={string("contact.mail")}
                type="email"
                className="form-control  px-4 py-3 border placeholder:text-grey2  border-grey2 w-full"
                style={{ direction: "RTL" }}
              />
              <ErrorMessage name="email" component="div" className="error" />
            </div>

            <div className="w-full">
              <Field
                as="select"
                name="position"
                className="form-control px-4 py-3 border placeholder:text-grey2 border-grey2 w-full"
              >
                {positions.map((e, index) => (
                  <option value={e} key={index}>
                    {e}
                  </option>
                ))}
              </Field>

              <ErrorMessage name="position" component="div" className="error" />
            </div>

            <div className="w-full">
              <Field
                id="company"
                name="company"
                placeholder={string("contact.company")}
                className="form-control px-4 py-3 border placeholder:text-grey2 border-grey2 w-full"
              />
              <ErrorMessage
                name="company"
                component="div"
                className="error"
              />
            </div>
            
            <div className="w-full">
              <Field
              type="number"
                id="participants"
                name="participants"
                step="1"
                min="1"
                max="2"
                className="form-control  px-4 py-3 border placeholder:text-grey2  border-grey2 w-full"
                placeholder={string("contact.participants")}
              />
              <ErrorMessage name="participants" component="div" className="error" />
            </div>

            

            {/* <div className="w-full">

              <Field
                as={"textarea"}
                id="text"
                name="text"
                placeholder={string('contact.body')}
                className="form-control border px-4 py-3 placeholder:text-grey2 border-grey2 w-full"
                rows="7"
              />
              <ErrorMessage name="text" component="div" className="error" />
            </div> */}
          </div>

          <div className="text-center max-w-4xl mx-auto">
            <button
              type="submit"
              className="text-center border bg-navy text-white px-4 py-3 w-full"
            >
              {string("contact.send")}{" "}
              {loading && (
                <>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">
                    {string("form.loading...")}
                  </span>
                </>
              )}
            </button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default EventForm;
